<template>
  <div id="q-app">
    <div class="calendar">
      <Calendar />
    </div>
  </div>
</template>

<script>
import Calendar from './components/Calendar.vue'

export default {
  name: 'App',
  components: {
    Calendar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
/*  height: 100vh;
  width: calc(100vw - 2em);
}

#q-app{
  width: 100%;
  height: 100%;
}

#app .calendar{
  width: 100%;
  height: 100%;*/
}

body{
  margin: 0;
}
</style>
