<template>
  <div
    style="position: fixed; top: 0; left: 0; right: 0; bottom: 0"
    on-touchstart="startingTouch"
    on-touchend="endTouch"
  >
    <full-calendar :options="calendarOptions" ref="calendar"></full-calendar>
    <event-form
      ref="eventForm"
      @done="reload"
      :moments="moments"
      v-model:date="eventDate"
    />
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import timeGridPlugin from "@fullcalendar/timegrid";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import nlLocale from "@fullcalendar/core/locales/nl";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import EventForm from "./EventForm.vue";

const moments = [
  { start: "10:00", end: "12:00" },
  { start: "13:30", end: "17:00" },
  { start: "18:00", end: "19:00" },
];

let refresher = 0;
let calendar = null;
export default {
  components: {
    FullCalendar,
    EventForm,
  },
  data() {
    refresher++;
    const self = this;
    self.runRefresher(refresher);
    self.setGlobalCalendar();
    return {
      eventDate: null,
      moments: moments,
      calendarOptions: {
        dateClick: function (info) {
          const date = new Date(
            info.date.getTime() - info.date.getTimezoneOffset() * 60000
          );
          let start = date.toISOString().split("T")[1].substring(0, 5);
          let end =
            parseInt(start.split(":")[0]) + 1 + ":" + start.split(":")[1];
          if (end.length < 5) end = "0" + end;
          const mapped = moments.find((a) => {
            return a.start <= start && a.end >= start;
          });
          if (mapped) {
            start = mapped.start;
            end = mapped.end;
          }
          self.$refs.eventForm.openModal({
            date: date.toISOString().split("T")[0],
            start,
            end,
            title: "",
          });

          // change the day's background color just for fun
          //info.dayEl.style.backgroundColor = 'red';
        },
        eventClick: function (info) {
          const startDate = new Date(
            info.event.start.getTime() -
              info.event.start.getTimezoneOffset() * 60000
          );
          const endDate = new Date(
            info.event.end.getTime() -
              info.event.end.getTimezoneOffset() * 60000
          );
          self.$refs.eventForm.openModal({
            id: info.event.id,
            date: startDate.toISOString().split("T")[0],
            start: startDate.toISOString().split("T")[1].substring(0, 5),
            end: endDate.toISOString().split("T")[1].substring(0, 5),
            title: info.event.title,
          });
          info.jsEvent.preventDefault();
        },
        selectConstraint: "businessHours",
        locale: nlLocale,
        nowIndicator: true,
        plugins: [
          timeGridPlugin,
          googleCalendarPlugin,
          interactionPlugin,
          bootstrap5Plugin,
        ],
        themeSystem: "bootstrap5",
        initialView: "timeGridWeek",
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "timeGridWeek,timeGridDay", // user can switch between the two
        },
        height: "100%",
        firstDay: 1,
        weekends: true,
        googleCalendarApiKey: "AIzaSyD4oDlbh03OhU1ivofhl40hBK2HvtxMjQA",
        events: {
          googleCalendarId:
            "d656b0f6c9e12f105ef8633530bcece57a988e304668988a5bae32f52c0cd49c@group.calendar.google.com",
        },
        businessHours: moments.map((m) => {
          return {
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            startTime: m.start,
            endTime: m.end,
          };
        }),
      },
    };
  },
  setup() {
    document.addEventListener("keyup", (event) => {
      const currentView = calendar.view;
      const currentDate = currentView.currentStart;

      if (event.key === "ArrowRight") {
        currentDate.setDate(currentDate.getDate() + 7);
        currentView.calendar.gotoDate(currentDate);
      }

      if (event.key === "ArrowLeft") {
        currentDate.setDate(currentDate.getDate() - 7);
        currentView.calendar.gotoDate(currentDate);
      }
    });
  },
  watch: {
    eventDate: {
      handler(newDate) {
        calendar.view.calendar.gotoDate(new Date(newDate + "T" + calendar.view.currentStart.toISOString().split("T")[1]));
      },
    },
  },
  methods: {
    setGlobalCalendar() {
      calendar = this.$refs?.calendar?.calendar;
      if (calendar == null) {
        const self = this;
        setTimeout(() => {
          self.setGlobalCalendar();
        }, 100);
      }
    },
    reload() {
      this.$refs.calendar.calendar.refetchEvents();
    },
    runRefresher(check) {
      const self = this;
      setTimeout(function () {
        if (check != refresher) return;
        try {
          self.reload();
          // eslint-disable-next-line no-empty
        } catch {}
        self.runRefresher(check);
      }, 5 * 60 * 1000);
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>