<!-- src/components/EventForm.vue -->
<template>
  <div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      v-show="modalShow"
      :class="{ 'd-block': modalShow }"
      @click.stop="closeModal"
      style="background-color: rgba(0, 0, 0, 0.6)"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        @click.stop
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Bezoek {{ id != -1 ? "aanpassen" : "toevoegen" }}
            </h5>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent.stop="addEvent" class="p-3">
              <div class="mb-3 text-start">
                Kijk even of er al geen bezoeken/afspraken zijn gepland op jouw moment zodat we grootmoe niet overbelasten. Er zijn ook 3 momenten dat je best op bezoek kan gaan, het avondmoment kan maar dan is ze al wat moe dus hou hiermee rekening.
                <ul>
                  <li v-for="(moment, index) in moments" :key="index"><a href="#" @click="setMoment(moment)">{{ moment.start }} tot {{ moment.end }}</a></li>
                </ul>
              </div>
              <div class="mb-3">
                <label for="eventTitle" class="form-label">Bezoeker(s):</label>
                <input
                  v-model="eventTitle"
                  type="text"
                  id="eventTitle"
                  class="form-control"
                  required
                />
              </div>

              <div class="mb-3">
                <label for="date" class="form-label">Datum:</label>
                <input
                  v-model="date"
                  type="date"
                  id="date"
                  class="form-control"
                  @input="$emit('update:date', $event.target.value)"
                  required
                />
              </div>

              <div class="mb-3">
                <label for="start" class="form-label">Start uur:</label>
                <input
                  v-model="start"
                  type="time"
                  id="start"
                  class="form-control"
                  required
                />
              </div>

              <div class="mb-3">
                <label for="end" class="form-label">Eind uur:</label>
                <input
                  v-model="end"
                  type="time"
                  id="end"
                  class="form-control"
                  required
                />
              </div>

              <button type="submit" class="btn btn-primary">
                {{ id != -1 ? "aanpassen" : "toevoegen" }}
              </button>
              <button @click="deleteRequest" type="button" class="btn btn-danger" @if="id != -1" style="margin-left: 1em;">
                verwijderen
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["done", "update:date"],
  props: ["moments"],
  data() {
    return {
      id: -1,
      eventTitle: "",
      date: "",
      start: "",
      end: "",
      modalShow: false,
    };
  },
  methods: {
    async addEvent() {
      try {
        const event = {
          summary: this.eventTitle,
          start: {
            dateTime: new Date(
              this.date + "T" + this.start + ":00"
            ).toISOString(),
          },
          end: {
            dateTime: new Date(
              this.date + "T" + this.end + ":00"
            ).toISOString(),
          },
        };

        const response = await fetch(process.env.VUE_APP_BACKEND, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            eventId: this.id == -1 ? null : this.id,
            event,
          }),
        });

        const result = await response.json();
        console.log("Event created:", result.result);

        this.modalShow = false; 
        this.$emit("done");
      } catch (error) {
        console.error("Error adding event:", error);
        alert("Er liep iets mis: " + error);
      }
    },
    deleteRequest(){
        if(window.confirm("Wilt u het eventement '" + this.eventTitle + "' verwijderen?")){
                this.doDelete();
        }
    },
    async doDelete(){
      try {
        const response = await fetch(process.env.VUE_APP_BACKEND, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            eventId: this.id == -1 ? null : this.id,
          }),
        });

        const result = await response.json();
        console.log("Event deleted:", result.result);

        this.modalShow = false; 
        this.$emit("done");
      } catch (error) {
        console.error("Error deleting event:", error);
        alert("Er liep iets mis: " + error);
      }
    },
    setMoment(moment){
      this.start = moment.start;
      this.end = moment.end;
    },
    openModal(event) {
      if (event == null) {
        this.id = -1;
        this.eventTitle = "";
        this.date = "";
        this.start = "";
        this.end = "";
      } else {
        this.id = event.id ?? -1;
        this.eventTitle = event.title;
        this.date = event.date;
        this.start = event.start;
        this.end = event.end;
      }
      this.modalShow = true;
    },
    closeModal() {
      this.modalShow = false;
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>